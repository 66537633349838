var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[(_vm.postsDataArray === undefined)?_c('div',[_vm._m(0)]):_vm._e(),(_vm.message.success !== undefined)?_c('div',{staticClass:"messages-banner"},[_c('div',{staticClass:"alert alert-success fixed-top text-center message-banner",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.message.success)+" ")])]):_vm._e(),(_vm.message.error !== undefined)?_c('div',{staticClass:"messages-banner"},[_c('div',{staticClass:"alert alert-error fixed-top text-center message-banner",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.message.error)+" ")])]):_vm._e(),(_vm.postsDataArray === '')?_c('div',[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header d-flex justify-content-between"},[_vm._v(" "+_vm._s(_vm.element.title)+" ")]),_c('div',{staticClass:"editor-box"},[_c('div',{staticClass:"card-body"},[_c('Editor',{attrs:{"id":"editor-1","api-key":"6is403i9xkcoj8amrkrben1t5g3998kxurdtnttbrqdn797i","init":{
                    height: 500,
                    width: 1070,
                    menubar: false,
                    statusbar: false,
                    fontsize_formats: '70pt 80pt 90pt 102pt 140pt',
                    language: 'de',
                    toolbar:
                      'myFullscreen | undo redo | bold italic | bullist numlist | outdent indent | fontsizeselect',
                    setup(editor) {
                      editor.ui.registry.addButton('myFullscreen', {
                        text: 'Vollbild',
                        onAction() {
                          _vm.setEditorToFullscreen(-1);
                        },
                      });
                    },
                  }},model:{value:(_vm.element.data),callback:function ($$v) {_vm.$set(_vm.element, "data", $$v)},expression:"element.data"}}),_c('button',{staticClass:"btn btn-success",attrs:{"id":"button-1"},on:{"click":function($event){return _vm.saveData(_vm.index)}}},[_vm._v(" Speichern ")])],1)])])])])]):_vm._e(),(_vm.postsDataArray !== undefined || _vm.postsDataArray !== '')?_c('div',{},_vm._l((_vm.postsDataArray),function(element,index){return _c('div',{key:`${index}`,staticClass:"d-flex justify-content-between"},[(element.editable !== false)?_c('div',[_c('div',{staticClass:"col-md-12 p-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header d-flex justify-content-between"},[_vm._v(" "+_vm._s(element.title)+" ")]),_c('div',{staticClass:"card-body"},[_c('Editor',{attrs:{"id":'editor' + index,"init":{
                    height: 300,
                    width: 1070,
                    menubar: false,
                    statusbar: false,
                    fontsize_formats: '70pt 80pt 90pt 102pt 140pt',
                    language: 'de',
                    toolbar:
                      'myFullscreen | undo redo | bold italic | bullist numlist | outdent indent | fontsizeselect',
                    setup(editor) {
                      editor.ui.registry.addButton('myFullscreen', {
                        text: 'Vollbild',
                        onAction() {
                          _vm.setEditorToFullscreen(index);
                        },
                      });
                    },
                  }},model:{value:(element.data),callback:function ($$v) {_vm.$set(element, "data", $$v)},expression:"element.data"}}),_c('button',{staticClass:"btn btn-success",attrs:{"id":'button' + index},on:{"click":function($event){return _vm.saveData(index)}}},[_vm._v(" Speichern ")])],1)])])]):_vm._e()])}),0):_vm._e()])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }