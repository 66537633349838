import Vue from 'vue';
import Login from '../components/Login'
import Posts from '../components/Posts'
import Preview from '../components/Preview'
import { getAuth } from "firebase/auth"
import Router from 'vue-router';
import NotAllowed from '../components/NotAllowedView'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
    { 
        path: '*',
        redirect: '/login' 
    },
    { 
        path: '/', 
        redirect: '/login' 
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/token/:token',
        name: 'token',
        component: Login,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/posts',
        name: 'posts',
        component: Posts,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/notallowed',
        name: 'NotAllowed',
        component: NotAllowed,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/preview',
        name: 'preview',
        component: Preview,
        meta: {
            requiresAuth: true
        }
    }
]
});

router.beforeEach(async (to, from, next) => {
    const auth = getAuth();
    const currentUser = await auth.currentUser
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

    const getIdTokenResult = currentUser ? await currentUser.getIdTokenResult(): false
    const  isUser = getIdTokenResult ? getIdTokenResult.claims.user : false
    
    if ((to.name === 'login' || to.name === 'NotAllowed') && currentUser && isUser) next({ name: 'posts' })
    else if (to.name !== 'NotAllowed' && !isUser && currentUser && requiresAuth) next({ name: 'NotAllowed' })
    else if (requiresAuth && currentUser) next()
    else if (requiresAuth && !currentUser) next({ name: 'login' })
    else next()
})

export default router