<template>
  <div id="app" style="margin: 0">
    <navbar></navbar>
      <router-view></router-view>
  </div>
</template>
<script>
import navbar from "./components/Navbar";
import { getAuth, onAuthStateChanged } from "firebase/auth";
export default {
  components: {
    navbar,
  },
  componentDidMount() {
    onAuthStateChanged(
      getAuth(),
      function (user) {
        if (!user) {
          this.$router.replace({
            name: "login",
          });
          return;
        } else {
          this.$router.replace({
            name: "posts",
          });
        }
      }.bind(this)
    );
  },
};
</script>

<style scoped>
#app {
  width: calc(100vw - 14px) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}
</style>