<template>
  <svg data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219 219" :style="`width: ${width}; height: ${height};`"><path :style="`fill: ${fill}`" d="M200.87,0H18.13A18.16,18.16,0,0,0,0,18.14V200.87A18.13,18.13,0,0,0,18.13,219H200.87A18.13,18.13,0,0,0,219,200.87h0V18.14A18.16,18.16,0,0,0,200.87,0ZM109.23,32c37.11,0,67.76,8.51,76.61,50.56H140.72c-2.38-8.68-8.34-23.32-31.32-23.32-25.4,0-33.72,17.17-33.72,37.25s7.49,37.28,33.72,37.28c18.21,0,29.11-9.19,31.84-23.32h44.94C181.24,136.67,166.77,161,109.23,161c-51.4,0-76.4-22.64-76.4-64.52C32.83,55.81,56.45,32,109.23,32Zm77,157.51h-153v-6h153Z"/></svg>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    height: String,
    width: String,
    fill: String
  }
}
</script>
