import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./routes/index";

// Font Awesome
import "./fontawesome.js";

// Bootstrap
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@comlinedev/bootstrap4/dist/css/bootstrap.css";

// Firebase
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator, setPersistence, onAuthStateChanged, browserLocalPersistence } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";

initializeApp({
  apiKey: "AIzaSyDROApW8DW-7Mvu3ybTdxbPhEslPZ4Rsmw",
  authDomain: "clgmbh-logistikmonitor.firebaseapp.com",
  databaseURL: "https://clgmbh-logistikmonitor.firebaseio.com",
  projectId: "clgmbh-logistikmonitor",
  storageBucket: "clgmbh-logistikmonitor.appspot.com",
  messagingSenderId: "283537869289",
  appId: "1:283537869289:web:dcdb99c0dd537bbb08e378",
});

const auth = getAuth();
const db = getDatabase();
const firestore = getFirestore();

if (location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectDatabaseEmulator(db, "localhost", 9001);
  connectFirestoreEmulator(firestore, "localhost", 8081);
}

Vue.use(BootstrapVue);
Vue.config.productionTip = true;

// App Init
setPersistence(auth, browserLocalPersistence).then(() => {
  onAuthStateChanged(auth, (user) => {
    store.dispatch("fetchUser", user);
    let app = "";
    if (!app) {
      app = new Vue({
        render: (h) => h(App),
        router,
        store,
      });
      app.$mount("#app");
    }
  });
});
