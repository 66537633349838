// Vue
import Vue from 'vue'

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { 
  faSignOut,
 
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faSignOut,

)
Vue.component('font-awesome-icon', FontAwesomeIcon)