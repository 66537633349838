<template>
  <div class="backgroundFrame">
    <div v-if="user">
      <hooper
        :progress="false"
        :autoPlay="true"
        :wheelControl="false"
        :hoverPause="false"
        :playSpeed="10000"
      >
        <slide id="pl-slide">
          <p-l-table />
        </slide>
        <slide v-for="(element, index) in postsDataArray" :key="index">
          <div v-if="element.date !== ' '" class="timestamp">
            Stand: {{ element.date }}
          </div>
          <div
            v-html="element.data"
            style="height: 100%; width: 100vw; padding: 25px"
          ></div>
        </slide>
        <!-- Set loading -->
        <div slot="loading">loading...</div>
      </hooper>
     <!-- <div v-if="showtime" id="pltable" :key="666">
        <p-l-table />
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getDatabase, ref, onValue } from "firebase/database";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import PLTable from "./PLTable.vue";

export default {
  components: {
    Hooper,
    Slide,
    PLTable,
  },
  data() {
    return {
      postsDataArray: [],
      weather: [],
      tableData: {},
      showtime: false,
      waitAsec: false,
    };
  },
  methods: {
    async loadPostsData() {
      onValue(ref(getDatabase(), `/posts`), (snapshot) => {
        this.postsDataArray = snapshot.val();
        return null;
      });
    },
    mountObserver() {
      const targetNode = document.getElementById("pl-slide");
      const config = { attributes: true };

      const callback = () => {
        setTimeout(this.sliderToggle(), 5000)
        console.log("observer notice");
      };
      const observer = new MutationObserver(callback);
      observer.observe(targetNode, config);
    },
    isInViewport() {
      const element = document.getElementById("pl-slide");
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    async sliderToggle() {
      let requirements = await this.isInViewport();
      if (!this.showtime && requirements && !this.waitAsec) {
        console.log("timer initiated");
        this.waitAsec = true;
        setTimeout(() => {
          this.showtime = true;
        }, 2000);
        setTimeout(() => {
          this.showtime = false;
        }, 40000);
        setTimeout(() => {
          this.waitAsec = false;
        }, 50000);
      } else {
        console.log("recirements for true not met", requirements);
        return false;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  async beforeMount() {
    this.loadPostsData();
  },
  mounted() {
    //this.mountObserver();
    //setTimeout(this.sliderToggle, 42000);
  },
};
</script>
<style>
.slider-item {
  max-width: 100% !important;
  max-height: 100% !important;
}
.hooper,
#pltable {
  color: white;
  height: calc(100vh - 60px) !important;
  line-height: 1;
}
#pltable {
  position: absolute;
  top: 60px;
  width: calc(100vw - 15px);
  background-color: #303030;
}
.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
table#tagesinformationen {
  font-size: 102pt;
}
img.wind,
img.temp {
  height: 66%;
  margin-right: 25px;
}
table#weather {
  font-size: 102pt;
}
table#tagesinformationen {
  font-size: 102pt;
}
.timestamp {
  position: relative;
  top: 10px;
  font-size: 30pt;
  width: 100vw;
  text-align: right;
  padding-right: 30px;
}
hr {
  background-color: white;
}
.backgroundFrame {
  width: 100%;
  height: calc(100vh - 60px);
  background: center bottom no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #303030;
}
</style>