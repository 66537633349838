<template>
  <div id="bucket">
    <div class="flex">
      <table v-if="T1 && sums.currentSums">
        <tr>
          <th class="header">PL Lieferbar:</th>
        </tr>
        <tr v-for="element in T1" :key="element[0]">
          <th>{{ element[0] }}</th>
          <td>{{ element[1] }} <small>PL</small></td>
          <td>{{ element[2] }} <small>Pos.</small></td>
        </tr>
        <tr class="sum-row">
          <th>Summe</th>
          <td>{{ sums.currentSums[0] }} <small>PL</small></td>
          <td>{{ sums.currentSums[1] }} <small>Pos.</small></td>
        </tr>
      </table>

      <table v-if="T2 && sums.incomingSums">
        <tr>
          <th class="header">PL in Arbeit:</th>
        </tr>
        <tr v-for="element in T2" :key="element[0]">
          <th>{{ element[0] }}</th>
          <td>{{ element[1] }} <small>PL</small></td>
          <td>{{ element[2] }} <small>Pos.</small></td>
        </tr>
        <tr class="sum-row">
          <th>Summe</th>
          <td>{{ sums.incomingSums[0] }} <small>PL</small></td>
          <td>{{ sums.incomingSums[1] }} <small>Pos.</small></td>
        </tr>
      </table>
    </div>

    <table v-if="T3" id="low-table">
      <tr v-if="this.T3.parkingPL !== 0" class="red">
        <th>PL geparkt:</th>
        <td>{{ T3.parkingPL }}</td>
      </tr>
      <tr v-else class="green">
        <th>PL geparkt:</th>
        <td>{{ T3.parkingPL }}</td>
      </tr>
      <tr v-if="this.T3.parkingPL !== 0" class="red">
        <th>Umlagerung ausstehend:</th>
        <td>{{ T3.pendingReloc }}</td>
      </tr>
      <tr v-else class="green">
        <th>Umlagerung ausstehend:</th>
        <td>{{ T3.pendingReloc }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { getDatabase, ref, onValue } from "firebase/database";
export default {
  name: "PLTable",
  data() {
    return {
      tableData: {},
      T1: {},
      T2: {},
      T3: {},
      sums: {},
    };
  },
  methods: {
    async developingProcess() {
      onValue(ref(getDatabase(), `/tableData`), (snapshot) => {
        this.tableData = snapshot.val();
        this.structureData()
        return null;
      });
    },
    structureData() {
      if (this.tableData.setups[0]) {
        let data = this.tableData;
        //construction of object
        let T1 = {
          standard: [
            "Standard",
            data.setups[0].auftraege_anzahl_standard,
            data.setups[0].auftraege_positionen_standard,
          ],
          single: [
            "Single Pick",
            data.setups[0].auftraege_anzahl_single,
            data.setups[0].auftraege_positionen_single,
          ],
          express: [
            "Express",
            data.setups[0].auftraege_anzahl_express +
              data.setups[1].auftraege_anzahl_express,
            data.setups[0].auftraege_positionen_express +
              data.setups[1].auftraege_positionen_express,
          ],
          singlePackage: [
            "Einzelversand",
            data.setups[1].auftraege_anzahl_standard,
            data.setups[1].auftraege_positionen_standard +
              data.setups[1].auftraege_positionen_single,
          ],
        };
        let T2 = {
          standard: [
            "Standart",
            data.setups[0].detail_in_arbeit.anzahl[1],
            data.setups[0].detail_in_arbeit.positionen[1],
          ],
          single: [
            "Single Pick",
            data.setups[0].detail_in_arbeit.anzahl[0],
            data.setups[0].detail_in_arbeit.positionen[0],
          ],
          paper: [
            "Papier",
            data.setups[0].detail_in_arbeit.anzahl[2],
            data.setups[0].detail_in_arbeit.positionen[2],
          ],
        };

        let T3 = {
          activePicker: data.anzahl_offene_websessions,
          parkingPL:
            data.setups[0].anzahl_geparkt + data.setups[1].anzahl_geparkt,
          pendingReloc: data.anzahl_offene_umlagerungen,
        };

        for (let i = 1; i < 3; i++) {
          if (isNaN(T1.standard[i])) T1.standard[i] = 0;
          if (isNaN(T1.single[i])) T1.single[i] = 0;
          if (isNaN(T1.express[i])) T1.express[i] = 0;
          if (isNaN(T1.singlePackage[i])) T1.singlePackage[i] = 0;
          if (isNaN(T2.standard[i])) T2.standard[i] = 0;
          if (isNaN(T2.single[i])) T2.single[i] = 0;
          if (isNaN(T2.paper[i])) T2.paper[i] = 0;
        }

        let sums = {
          currentSums: [
            T1.standard[1] + T1.single[1] + T1.express[1] + T1.singlePackage[1],
            T1.standard[2] + T1.single[2] + T1.express[2] + T1.singlePackage[2],
          ],
          incomingSums: [
            T2.standard[1] + T2.single[1] + T2.paper[1],
            T2.standard[2] + T2.single[2] + T2.paper[2],
          ],
        };
        this.T1 = T1;
        this.T2 = T2;
        this.T3 = T3;
        this.sums = sums;

        setTimeout(this.developingProcess, 10000);
      } else {
        setTimeout(this.developingProcess, 1000);
      }
    },
  },
  mounted() {
    this.developingProcess();
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: space-around;
}
table {
  font-size: 57pt;
}
td {
  text-align: center;
  padding: 15px;
}
th {
  padding: 10px;
}
small {
  font-size: 35pt;
  opacity: 0.85;
}
.header {
  font-size: 35pt;
}
.sum-row {
  border: 5px solid white;
  height: 91px;
}
#low-table {
  margin: 25px auto;
  font-size: 64pt;
}
#low-table td {
  padding-left: 50px;
  font-weight: bold;
}
.red {
  color: #ea6262;
}
.green {
  color: greenyellow;
}
</style>