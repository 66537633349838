<template>
  <nav v-if="user.loggedIn" class="navbar navbar-expand-lg navbar-dark bg-primary">
    <div class="container">
    <a class="navbar-brand" href="#">
      <Logo :fill="'#fff'" :height="'33px'" :width="'33px'" class="mb-0 mr-2 d-flex align-items-center"></Logo>
      Logistikmonitor
    </a>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto ml-3">
          <template v-if="user.loggedIn">
            <div class="nav-item">{{user.data.displayName}}</div>
            <li class="nav-item">
              <router-link to="/Posts" class="navbar-link">Beiträge</router-link>
            </li>
            <li class="nav-item ml-3">
              <router-link to="/Preview" class="navbar-link">Vorschau</router-link>
            </li>
          </template>
        </ul>
      </div>  
      <div>
        <template v-if="user.loggedIn">
          <a class="nav-link" @click.prevent="signOut" v-b-tooltip.hover.left title="Abmelden"><font-awesome-icon :icon="['fas', 'sign-out']" /></a>
        </template>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
import { getAuth, signOut } from "firebase/auth";
import Logo from '../assets/Logo'
export default {
  computed: {
    ...mapGetters({
// map `this.user` to `this.$store.getters.user`
      user: "user"
    })
  },
  components: {
    Logo,
  },
  methods: {
    signOut() {
      const auth = getAuth();
        signOut(auth)
        .then(() => {
          this.$router.replace({
            name: "login"
          });
        });
    }
  }
};
</script>
<style>
.navbar-brand {
  display: flex !important;
  flex-direction: row !important;
}
.navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
a.nav-link,
li a {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.788) !important;
}
a.nav-link:hover,
li a:hover {
  color: rgb(255, 255, 255) !important;
}
</style>